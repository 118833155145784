import React, { useEffect, useState } from "react";

import { BehaviorStepper } from "../reusableComponents";

import WhiteBox from "../shared/WhiteBox";
import { uploadQuestions } from "../../../../../api/jc";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssessmentIframe from "./AssessmentIframe";
import config from "../../../../../config/config";

function DriverOfEngagementStepFour() {

  const [mappedQuestions, setMappedQuestions] = useState([]);
  const [iFrameLink, setIFrameLink] = useState('')
  const questions = useSelector(state => state.behaviour.driverOfEngagement);
  const choice = useSelector(state => state.steps.driverOfEngagement);
  const navigate = useNavigate();

  useEffect(() => {
    if (questions?.length) {
      setMappedQuestions(questions.map(data => data?.question));
    }
  }, [questions])

  const handelSubmit = () => {

    const formData = []

    choice.forEach(x => {
      formData.push(questions.find(y => y.question === x));
    })
    let temp = {
      assessment: {
        assessment_type: "DriverOfEngagementDiagnostic",
        assessment_data_ordinality: formData
      }
    }
    uploadQuestions(temp).then((res) => {
      toast.success("Updated.")
      navigate('/assessment/rolebased/start')
    });

  }

  React.useEffect(() => {
    let data = localStorage.getItem('accessData');
    data = data && JSON.parse(data)
    console.log(data);
    let code = data?.isDriverOfEngagementDiagnosticCompleted || ''
    let fName = data?.firstNameAndLastName?.first_name || 'Abc'
    let lName = data?.firstNameAndLastName?.last_name || 'Abc'
    let gender = data?.firstNameAndLastName?.gender === 'male' ? 'm' : 'f'
    let email = data?.email_address || ''
    let iFrameSrc = `/assessment/value/Values/${code}/instrument/?fname=${fName}&lname=${lName}&gender=${gender}&email=${email}&completeURL=${window.location.origin}/assessment/values/complete/`
    setIFrameLink(iFrameSrc)
  }, []);

  return (
    <>
      <BehaviorStepper step={1} />
      <WhiteBox title="Welcome to the Values Test!">

        <p className="text-[#384259] text-base mb-5">
          This test is designed to help you discover the core values that influence your sense of purpose and direction. By understanding these values, you’ll gain insights that can lead to better career choices, personal growth, and overall fulfillment.
        </p>

        <p className="text-[#384259] text-base mb-2">
          <span className="text-[#000] font-bold">How It Works:</span>
        </p>

        <p className="text-[#384259] text-base mb-5">
            <ul className="list-disc pl-10">
                <li>You’ll be presented with a series of motivational statements that represent different aspects of personal and professional values.</li>
                <li>Arrange these statements in order of importance, from most important at the top to least important at the bottom.</li>
                <li>Feel free to adjust the order until you feel it accurately reflects your priorities.</li>
            </ul>
        </p>

        <p className="text-[#384259] text-base mb-5">
          When you're satisfied with your ranking, click <span className="font-bold">“Submit”</span> to proceed. There are ten stages in total, so take your time and consider each step carefully.
        </p>
        
        <p className="text-[#384259] text-base mb-5">
          We hope this process brings valuable insights and empowers you on your journey of self-discovery.
        </p>

        <AssessmentIframe iFrameLink={iFrameLink} />
      </WhiteBox>
      {/* <div className="max-w-[950px] w-full mx-auto my-5 flex items-center justify-between lg:px-0 md:px-10 sm:px-8 px-4">
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => navigate('/assessment/behavior/finish')}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#E3E6E9",
              fontSize: "16px",
              color: "#828282",
            },
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="contained"
          color="success"
          onClick={() => {
            if (choice.length < mappedQuestions.length) {
              toast.warning("Please Move All Items To Hear!!")
            } else {
              handelSubmit()
            }
          }}
          sx={{
            "&.MuiButton-containedSuccess": {
              backgroundColor: "#7AB794",
              fontSize: "16px",
            },
          }}
        >
          Submit
        </Button>
      </div> */}
    </>
  );
}

export default DriverOfEngagementStepFour;
